import React from 'react'
import banner from "../../assets/cars/carBackground1.jpg";
import serach from "../../assets/images/search.png";
import star from "../../assets/images/star.png";
import flag from "../../assets/images/flag.png";

const ChooseUs = () => {
  const divStyle = {
    backgroundImage: `url( ${banner})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    backgroundColor:"none",
  };

  return (
     < >
         <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16 mt-4">

             <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">
        Why Choose Us?
                </h4>

                <div className='p-4 mt-4 border-2'>
                  <p><span className='font-semibold text-xl pr-2'>Convenient One-Way Travel </span> We understand your need for flexible travel options. Our one-way taxi services allow you to book a cab for a single journey without the need for round trips, saving you both time and money.</p>
                  <p><span className='font-semibold text-xl pr-2 mt-2'>Reliable & Punctual </span> Our drivers are experienced and dedicated to providing you with a smooth and timely travel experience. We prioritize your safety and comfort above all.</p>
                  <p><span className='font-semibold text-xl pr-2 mt-2'>Competitive Pricing </span> We offer some of the most competitive rates in the region, making it affordable to travel between cities without compromising on quality.</p>

                </div>
                </div>

      {/* <div className="form_bg bg-fixed relative py-5 mx-auto px-4 md:px-8 lg:px-16" style={divStyle}>
            <div className="container flex flex-col  relative z-[10] text-white justify-between gap-y-5">
                <h2 className=" text-3xl font-semibold text-center">Why Choose Us? </h2>
              <div className="p-4 grid grid-cols-1 py-10 sm:grid-cols-1 md:grid-cols-3 md-py-28 lg:grid-cols-3  lg-py-28 gap-x-5 place-items-center">
                <div className=" grid grid-col-4 mb-5 md:mb-0 lg:mb-0 w-full h-full border-solid border-2 border-white bg-transparent color-white flex flex-col gap-y-3 p-3 rounded-lg">
                  <div >
                  <img src={serach}/>
                  </div>
                  <div>Choose Your Location
                  </div>
                  <ol>
                    <li>
                    1.Enter your pickup & Drop Location
                    </li>
                    <li>
                    2.Choose your Vehicle

</li>
                  </ol>
                </div>
                <div className=" grid grid-col-4 mb-5 md:mb-0 lg:mb-0 w-full h-full border-solid border-2 border-white bg-transparent color-white flex flex-col gap-y-3 p-3 rounded-lg">
                  <div>
                     <img src={star}/>
                     </div>
                     <div>Fill your Details
                     </div>
                  <ol>
                    <li>
                    1.Enter Your Name & Mobile
                    </li>
                    <li>
                    2.Select your Travel Date

</li>
                  </ol>
                </div>
                <div className=" grid grid-col-4 mb-5 md:mb-0 w-full h-full lg:mb-0 border-solid border-2 border-white bg-transparent color-white flex flex-col gap-y-3 p-3 rounded-lg ">
                 <div>
                  <img src={flag}/>
                  </div>
                  <div>Booking Confirmation
                  </div>
                  <ol>
                    <li>
                    Just relax, your booking confirmation will sent your mobile number.<br/>
                     Our representative will call you shortly.

</li>
                  </ol>
                </div>

              </div>
            </div>
      </div>
            <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16">
        <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-1 gap-y-3">
          <div className="p-6">
        <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">
        Why Choose Us?
                </h4>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Affordable Pricing:</h3>
          <p>
          We believe in transparent and competitive pricing with no hidden charges. Our rates are designed to offer you the best value for your money.          </p>
          <p>

          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Comfortable Rides:</h3>
          <p>
          Travel in comfort with our well-maintained fleet of vehicles. From sedans to SUVs, we have a variety of options to suit your needs.          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Experienced Drivers:</h3>
          <p>
          Our drivers are experienced, courteous, and familiar with the routes, ensuring you reach your destination safely and on time.            </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">24/7 Availability:</h3>
          <p>
          Whether it's an early morning flight or a late-night journey, our services are available round the clock to cater to your travel needs.           </p>
        </div>
        </div>
        <div className="p-6">
        <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">
        How It Works                </h4>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Book Your Ride:</h3>
          <p>
          Choose your pickup and drop-off locations, select your vehicle, and book your ride online.              </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Get Confirmation:</h3>
          <p>
          Receive instant confirmation of your booking with driver details.            </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Enjoy Your Journey:</h3>
          <p>
          Sit back, relax, and enjoy a smooth ride to your destination.                    </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Pay Easily:</h3>
          <p>
          Choose from multiple payment options for a hassle-free experience.              </p>
        </div>
        </div>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl test-center">Easy Booking:</h3>
          <p>
          Book your ride with ease through our user-friendly website or mobile app. Just a few clicks and you're ready to go!                  </p>
        </div>

      </div> */}
      </>

)
}

export default ChooseUs
