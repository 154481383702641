import React from "react";
import BookingForm from "../components/BookingForm";
import About from "../components/About";
import PopularRoutes from "../components/PopularRoutes";
import Cars from "../components/Cars";
import Look from "../components/Look";
import OurServices from "../components/Home/OurServices";
import OneWayServices from "../components/Home/OneWayServices";
import TopRoutes from "../components/Home/TopRoutes";
import PricePlan from "../components/Home/PricePlan";
import Vision from "../components/Home/Vision";
import Package from "../components/Home/Package";
import ChooseUs from "../components/Home/ChooseUs";
import Form from "../components/Form.js";
const Home = () => {
  return (
    <>
      {/* <HeaderSlider /> */}
      <BookingForm />
      {/* <div className="relative bg-fixed flex justify-center px-3">
        <div className='bg-white w-full md:w-[80%] lg:w-1/2  rounded-lg px-4  pb-4 relative z-10 mt-4 md:mt-8 mb-4 md:mb-8' id='book_taxi_form'>
          <Form />
        </div>
      </div> */}

      {/* <OurServices /> */}
      <About />
      <Package />
      {/* <PricePlan />
      <PopularRoutes /> */}
      {/* <ChooseUs  /> */}
      {/* <OneWayServices /> */}
      {/* <PopularRoutes /> */}
      {/* <Cars /> */}
            <Vision />
            <TopRoutes />
      <ChooseUs  />


      <Look />
    </>
  );
};

export default Home;
