import React from 'react'

const FooterRouteComponent = ({headings,Location1,Location2,Location3,Location4,Location5,Location6,Location7,Location8,Location9,Location10,
  Location11,Location12,Location13,Location15,Location16,Location17,Location18,Location19,Location20,Location21,Location22,Location23,Location24,Location25,Location26,Location14
}) => {
  return (
    <div className='grid grid-cols-1'>
       <h2 className='text-2xl text-center text-[#e30d16] font-bold'>{headings}</h2>
       <div className='text-white text-center text-md mt-2 opacity-75'>{Location1}</div>
       <div className='text-white text-center text-md mt-1 opacity-75'>{Location2}</div>
       <div className='text-white text-center text-md mt-1 opacity-75'>{Location3}</div>
<div className='text-white text-center text-md mt-1 opacity-75'>{Location4}</div>
       <div className='text-white text-center text-md mt-1 opacity-75'>{Location5}</div>
       <div className='text-white text-center text-md mt-1 opacity-75'>{Location6}</div>
       <div className='text-white text-center text-md mt-1 opacity-75'>{Location7}</div>
    <div className='text-white text-center text-md mt-1 opacity-75'>{Location8}</div>
       <div className='text-white text-center text-md mt-1 opacity-75'>{Location9}</div>
    <div className='text-white text-center text-md mt-1 opacity-75'>{Location10}</div>
       <div className='text-white text-center text-md mt-1 opacity-75'>{Location11}</div>
       <div className='text-white text-center text-md mt-1 opacity-75'>{Location12}</div>
       <div className='text-white text-center text-md mt-1 opacity-75'>{Location13}</div>
    <div className='text-white text-center text-md mt-1 opacity-75'>{Location14}</div>
    <div className='text-white text-center text-md mt-1 opacity-75'>{Location15}</div>
    <div className='text-white text-center text-md mt-1 opacity-75'>{Location16}</div>
    <div className='text-white text-center text-md mt-1 opacity-75'>{Location17}</div>
    <div className='text-white text-center text-md mt-1 opacity-75'>{Location18}</div>
    <div className='text-white text-center text-md mt-1 opacity-75'>{Location19}</div>
    <div className='text-white text-center text-md mt-1 opacity-75'>{Location20}</div>
       <div className='text-white text-center text-md mt-1 opacity-75'>{Location21}</div>
    <div className='text-white text-center text-md mt-1 opacity-75'>{Location22}</div>
    <div className='text-white text-center text-md mt-1 opacity-75'>{Location23}</div>
    <div className='text-white text-center text-md mt-1 opacity-75'>{Location24}</div>
    <div className='text-white text-center text-md mt-1 opacity-75'>{Location25}</div>
    <div className='text-white text-center text-md mt-1 opacity-75'>{Location26}</div>

    </div>
  )
}

export default FooterRouteComponent
