import React from 'react'
import FooterRouteComponent from './FooterRouteComponent'

const FooterRoute = () => {
    
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 py-12 px-5 gap-5">
      <FooterRouteComponent  headings="Chennai"  Location1="Chennai To Rameshwaram Taxi" Location2="Chennai To Trichy Taxi" Location3="Chennai To Madurai Taxi" Location4="Chennai To Palani Taxi" Location5="Chennai To Coimbatore Taxi" Location6="Chennai To Karur Taxi" Location7="Chennai To Kanyakumri Taxi" Location8="Chennai To Karadikudi Taxi" Location9="Chennai To Pondicherry Taxi" Location10="Chennai To Thiruppur Taxi" Location11="Chennai To Kodaikanal"
                                 Location12="Chennai To Ooty" Location13="Chennai To Thiruvannamalai" Location14="Chennai To Thanjavur" Location15="Chennai To Velankanni " Location16="Chennai To theni" Location17="Chennai To Munnar"  Location18="Chennai To Thiruvandram" />
      <FooterRouteComponent  headings="Coimbatore"  Location1="Coimbatore To Madurai Taxi" Location2="Coimbatore To Rameshwaram Taxi" Location3="Coimbatore To Chennai Taxi" Location4="Coimbatore To Palani Taxi" Location5="Coimbatore To Trichy Taxi" Location6="Coimbatore To Karur Taxi" Location7="Coimbatore To Kanyakumari Taxi" Location8="Coimbatore To Karaikudi Taxi" Location9="Coimbatore To Pondicherry Taxi" Location10="Coimbatore To Thiruppur Taxi"
      Location11="Coimbatore To Kodaikanal" Location12="Coimbatore To Ooty" Location13="Coimbatore To Thiruvannamalai" Location14="Coimbatore To Thanjavur" Location15="Coimbatore To Velankanni" Location16="Coimbatore To Theni" Location17="Coimbatore To Munnar" Location18="Coimbatore To Thiruvandram" Location19="Coimbatore To Coutrallam" Location20="Coimbatore To Palladam" Location21="Coimbatore To Gobichettipalayam" Location22="Coimbatore To Dharappuram" Location23="Coimbatore To Mettupalayam" Location24="Coimbatore To Palakad" Location25="Coimbatore To Erode" Location26="Coimbatore To Salem" Location27="Coimbatore To Pollachi" />
      <FooterRouteComponent  headings="Madurai"  Location1="Madurai To Ramehwaram Taxi" Location2="Madurai To Trichy Taxi" Location3="Madurai To Chennai Taxi" Location4="Madurai To Palani Taxi" Location5="Madurai To Coimbatore Taxi" Location6="Madurai To Karur Taxi" Location7="Madurai To Kanyakumari Taxi" Location8="Madurai To Karaikudi Taxi" Location9="Madurai To Pondicherry Taxi" Location10="Madurai To Thiruppur Taxi"
       Location11="Madurai To Kodaikanal" Location12="Madurai To Ooty" Location13="Madurai To Thiruvannamalai" Location14="Madurai To Thanjavur" Location15="Madurai To Velankanni" Location16="Madurai To Theni" Location17="Madurai To Munnar" Location18="Madurai To Thiruvandram" Location19="Madurai To Coutrallam" />
      <FooterRouteComponent  headings="Trichy"  Location1="Trichy To Madurai Taxi" Location2="Trichy To Rameshwaram Taxi" Location3="Trichy To Chennai Taxi" Location4="Trichy To Palani Taxi" Location5="Trichy To Coimbatore Taxi" Location6="Trichy To Karur Taxi" Location7="Trichy To Kanyakumari Taxi" Location8="Trichy To Karaikudi Taxi" Location9="Trichy To Pondicherry Taxi" Location10="Trichy To Thiruppur Taxi"
      Location11="Trichy To Kodaikanal" Location12="Trichy To Ooty" Location13="Trichy To Thiruvannamalai" Location14="Trichy To Thanjavur" Location15="Trichy To Velankanni" Location16="Trichy To Theni" Location17="Trichy To Munnar" Location18="Trichy To Thiruvandram" Location19="Trichy To Coutrallam" 
      />
      <FooterRouteComponent  headings="Rameshwaram"  Location1="Rameshwaram To Madurai Taxi" Location2="Rameshwaram To Trichy Taxi" Location3="Rameshwaram To Chennai Taxi" Location4="Rameshwaram To Palani Taxi" Location5="Rameshwaram To Coimbatore Taxi" Location6="Rameshwaram To Karur Taxi" Location7="Rameshwaram To KanyaKumari Taxi" Location8="Rameshwaram To Karaikudi Taxi" Location9="Rameshwaram To Pondicherry Taxi" Location10="Rameshwaram To Thiruppur Taxi"

        Location11="Chennai To Kodaikanal" Location12="Chennai To Ooty" Location13="Chennai To Thiruvannamalai" Location14="Chennai To Thanjavur" Location15="Chennai To Velankanni" Location16="Chennai To Theni" Location17="Chennai To Munnar" Location18="Chennai To Thiruvandram" Location19="Chennai To Coutrallam" />

    </div>
  )
}

export default FooterRoute
